/* website: 4405-cranbrookkia1
 * created at 2024-05-09 12:30 by Mfaye
 */

// Import all makes and organization styles files
@import "../templates/makes/kia.scss";
@import "../utils/icons.scss";

.widget-sr{
  @include clearfix;
  width: 100%;
  &.dealer__cranbrookkia-381{
    .listing-tile .listing-tile-image .car-info{
      position: relative;
    }
    .listing-tile .listing-tile-image{
      display:flex;
      flex-direction: column-reverse;
    }
  }
}